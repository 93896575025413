import { useQuery } from "@tanstack/react-query";
import { warehouseApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsWareHouseI,
  WareHouseI,
  ObjPostI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getWareHouses = async ({
  pagination,
  sorting,
  globalFilter,
  client,
  executive,
}: PropsWareHouseI): Promise<ServiceResponseI<DataI<WareHouseI>>> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", JSON.stringify(client));
    executive && params.append("executive", JSON.stringify(executive));
    //headers: { 'Authorization': validateToken
    const { data } = await warehouseApi.get<
      ServiceResponseI<DataI<WareHouseI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWareHouseByClientIdAndCategoryId = async (
  client: number,
  category: number,
  typeWork?:number|null,
  type?:number|null,
  process?:number|null,
  statusClient?:number|null,
  statusElement?:number|null,
): Promise<ServiceResponseI<WareHouseI[]>> => {
  try {
    const params = new URLSearchParams();
    client && params.append("client", JSON.stringify(client));
    category && params.append("category", JSON.stringify(category));
    typeWork && params.append("typeWork", JSON.stringify(typeWork));
    type && params.append("type", JSON.stringify(type));
    process && params.append("process", JSON.stringify(process));
    statusClient && params.append("statusClient", JSON.stringify(statusClient));
    statusElement && params.append("statusElement", JSON.stringify(statusElement));

    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await warehouseApi.get<ServiceResponseI<WareHouseI[]>>(
      `/all-by-client-category`,
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWareHouseChildrenByParentId = async (
  id: number
): Promise<ServiceResponseI<WareHouseI[]>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await warehouseApi.get<ServiceResponseI<WareHouseI[]>>(
      `/all-by-parent-id/${id}`,
      { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWareHouses = async (): Promise<
  ServiceResponseI<WareHouseI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await warehouseApi.get<ServiceResponseI<WareHouseI[]>>(
      `/all-no-pagination`,
      { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehousePost = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.post<ServiceResponseI<ObjPostI>>(
      "",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseContinous = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.post<ServiceResponseI<ObjPostI>>(
      "/continuous",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseReturn = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.post<ServiceResponseI<ObjPostI>>(
      "/return",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseDiscard = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.post<ServiceResponseI<ObjPostI>>(
      "/discard",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseMaintenance = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.post<ServiceResponseI<ObjPostI>>(
      "/maintenance",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehousePut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.put<ServiceResponseI<string>>(
      `/${id}`,
      updateForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseById = async (
  id: number
): Promise<ServiceResponseI<WareHouseI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.get<ServiceResponseI<WareHouseI>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const warehouseDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await warehouseApi.delete<ServiceResponseI<string>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useWareHouses = ({
  sorting,
  pagination,
  globalFilter,
  client,
  executive,
}: PropsWareHouseI) => {
  const warehousesQuery = useQuery(
    ["warehouses", { pagination, sorting, globalFilter, client, executive }],
    () =>
      getWareHouses({ pagination, sorting, globalFilter, client, executive }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    warehousesQuery,
  };
};
