import React from "react";
import {
    Navigate,
} from "react-router-dom";


const ServiceListComponent = React.lazy(() => import("../../../../../components/service/components/list/page"));
const ServiceAddComponent = React.lazy(() => import("../../../../../components/service/components/add/page"));

export const servicesRoute = [
    {
        path: 'list',
        element: <ServiceListComponent />
    },
    {
        path: 'add',
        element: <ServiceAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/options/service/add" />
    },
    {
        path: 'edit/:id',
        element: <ServiceAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/options/service/list" />
    },
];