import { useQuery } from "@tanstack/react-query";
import { cataloguesApi } from "./axios";
import {
    CatalogueI,
    CreateCatalogueChildI,
    DataI,
    PropsCataloguesI,
    PropsI,
    ServiceResponseI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers';
import { AxiosError } from 'axios';


const getCatalogues = async ({
    pagination,
    sorting,
    globalFilter,
    parent,
    all,
}: PropsCataloguesI): Promise<ServiceResponseI<DataI<CatalogueI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        parent && params.append('parent', parent.toString());
        all && params.append('all', all.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await cataloguesApi.get<ServiceResponseI<DataI<CatalogueI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)

    }


}

export const getAllCatalogueChildrenbyParentCode = async (code: string) => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('code', code);

        const { data } = await cataloguesApi.get<ServiceResponseI<CatalogueI[]>>("/all-children-by-parent-code", { params, headers: { 'Authorization': validateToken } });

        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)

    }
}

export const getAllCatalogueParents = async () => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await cataloguesApi.get<ServiceResponseI<CatalogueI[]>>("/all-catalogues-parents", { headers: { 'Authorization': validateToken } });

        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const createCatalogueChild = async (form: CreateCatalogueChildI): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cataloguesApi.post<ServiceResponseI<string>>("/child", form, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const catalogueDelete = async<T>(id: number): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cataloguesApi.delete(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const cataloguePost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cataloguesApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const cataloguePut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cataloguesApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const catalogueById = async (id: number): Promise<ServiceResponseI<CatalogueI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await cataloguesApi.get<ServiceResponseI<CatalogueI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const catalogueByCode = async (code: string): Promise<ServiceResponseI<CatalogueI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('code', code);

        const { data } = await cataloguesApi.get<ServiceResponseI<CatalogueI>>(`/by-code`, { params, headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useCatalogues = ({
    sorting,
    pagination,
    globalFilter,
    parent,
    all,
}: PropsCataloguesI) => {


    const cataloguesQuery = useQuery(
        ["catalogues", { pagination, sorting, globalFilter, parent, all }],
        () => getCatalogues({ pagination, sorting, globalFilter, parent, all }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );

    return {
        cataloguesQuery,
    };
}