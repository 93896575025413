import { useQuery } from "@tanstack/react-query";
import { warehouseHistoricApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsWareHouseI,
    WareHouseHistoricI,
    ObjPostI,
    PropsWareHouseHistoricI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getWareHouseHistorics = async ({
    pagination, 
    sorting, 
    globalFilter,
    client,
    executive,
}:PropsWareHouseI):Promise<ServiceResponseI<DataI<WareHouseHistoricI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        client && params.append('client', JSON.stringify(client));
        executive && params.append('executive', JSON.stringify(executive));
        //headers: { 'Authorization': validateToken
        const { data } = await warehouseHistoricApi.get<ServiceResponseI<DataI<WareHouseHistoricI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }

    
}

export const getAllWareHouseHistoricByWareHouseId = async (warehouse:number):Promise<ServiceResponseI<WareHouseHistoricI[]>> =>{
    try{ 

        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await warehouseHistoricApi.get<ServiceResponseI<WareHouseHistoricI[]>>(`/all-by-warehouse-id/${warehouse}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const warehouseHistoricPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await warehouseHistoricApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const warehouseHistoricPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await warehouseHistoricApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const warehouseHistoricById = async (id: number): Promise<ServiceResponseI<WareHouseHistoricI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await warehouseHistoricApi.get<ServiceResponseI<WareHouseHistoricI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const warehouseHistoricGeneratePdfReturnById = async (id: number): Promise<ServiceResponseI<WareHouseHistoricI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await warehouseHistoricApi.get<ServiceResponseI<WareHouseHistoricI>>(`/generate-pdf-return/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const warehouseHistoricDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await warehouseHistoricApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useWareHouseHistorics = ({
    sorting,
    pagination,
    globalFilter,
    client,
    executive,
}:PropsWareHouseHistoricI)=>{

    
    const warehouseHistoricsQuery = useQuery(
        ["warehouseHistorics", { pagination, sorting, globalFilter, client, executive, }],
        ()=>getWareHouseHistorics({ pagination, sorting, globalFilter, client, executive, }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        warehouseHistoricsQuery,
    };
}