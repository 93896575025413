import { useQuery } from "@tanstack/react-query";
import { projectApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsProjectI,
  ProjectI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getProjects = async ({
  pagination,
  sorting,
  globalFilter,
  client,
  executive,
}: PropsProjectI): Promise<ServiceResponseI<DataI<ProjectI>>> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", client.toString());
    executive && params.append("executive", executive.toString());

    //headers: { 'Authorization': validateToken
    const { data } = await projectApi.get<ServiceResponseI<DataI<ProjectI>>>(
      "",
      { params, headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

const getProjectForApprove = async ({
  pagination,
  sorting,
  globalFilter,
  client,
  executive,
}: PropsProjectI): Promise<ServiceResponseI<DataI<ProjectI>>> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", client.toString());
    executive && params.append("executive", executive.toString());

    //headers: { 'Authorization': validateToken
    const { data } = await projectApi.get<ServiceResponseI<DataI<ProjectI>>>(
      "/all-for-approve",
      { params, headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllProjects = async (): Promise<
  ServiceResponseI<ProjectI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await projectApi.get<ServiceResponseI<ProjectI[]>>(
      `/all-no-pagination`,
      { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const projectPost = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.post<ServiceResponseI<string>>(
      "",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const projectPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.put<ServiceResponseI<string>>(
      `/${id}`,
      updateForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const sendProjectToGerenciaComercialById = async (
  id: number
): Promise<ServiceResponseI<ProjectI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.get<ServiceResponseI<ProjectI>>(
      `/send-to-gerencia-comercial/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const createProjectPdfById = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.get<ServiceResponseI<string>>(
      `/create-pdf/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const projectById = async (
  id: number
): Promise<ServiceResponseI<ProjectI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.get<ServiceResponseI<ProjectI>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const projectDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await projectApi.delete<ServiceResponseI<string>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useProjects = ({
  sorting,
  pagination,
  globalFilter,
  client,
  executive,
}: PropsProjectI) => {
  const projectsQuery = useQuery(
    ["projects", { pagination, sorting, globalFilter, client, executive }],
    () => getProjects({ pagination, sorting, globalFilter, client, executive }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    projectsQuery,
  };
};

export const useProjectForApprove = ({
  sorting,
  pagination,
  globalFilter,
  client,
  executive,
}: PropsProjectI) => {
  const projectForApproveQuery = useQuery(
    ["projects", { pagination, sorting, globalFilter, client, executive }],
    () =>
      getProjectForApprove({
        pagination,
        sorting,
        globalFilter,
        client,
        executive,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    projectForApproveQuery,
  };
};
