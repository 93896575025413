import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from "@mui/material";

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { useRouterStore } from "../../../libs/stores";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogCloseSessionProps {
  open: boolean;
  handleClose: () => void;
}
export const DialogCloseSession = ({
  open,
  handleClose,
}: DialogCloseSessionProps) => {
  const navigate = useNavigate();
  const reset = useRouterStore((state) => state.reset);
  const [isClosing, setClosing] = useState(false);
  const [isContinuo, setContinuo] = useState(false);

  useEffect(() => {
    if (isContinuo) {
      handleCloseSession();
    }
  }, [isContinuo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseSession = () => {
    reset();
    localStorage.removeItem(process.env.REACT_APP_TOKEN!);
    localStorage.removeItem(process.env.REACT_APP_TOKEN_OPTIONS!);
    setTimeout(() => {
      navigate("/", { replace: true });
      setClosing(false);
      handleClose();
    }, 2000);
  };

  const handleContinuo = () => {
    setClosing(true);
    setTimeout(() => {
      setContinuo(true);
    }, 1000);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
    >
      <div style={{ background: "#F8F8FF" }}>
        <DialogTitle
          id="form-dialog-title"
          sx={{
            textAlign: "center",
            fontSize: 18,
            fontWeight: "bolder",
            color: "red",
          }}
        >
          Seguro que desea Salir?
        </DialogTitle>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            onClick={handleClose}
            size="medium"
            color="error"
            variant="contained"
            sx={{ width: 100 }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleContinuo}
            color="primary"
            variant="contained"
            size="medium"
            sx={{ width: 100 }}
            endIcon={
              isClosing ? (
                <CircularProgress size={20} color="error" />
              ) : (
                <CheckCircleIcon />
              )
            }
          >
            Continuar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
