import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ParameterListComponent = React.lazy(() => import("../../../../../components/parameter/components/list/page"));
const ParameterAddComponent = React.lazy(() => import("../../../../../components/parameter/components/add/page"));

export const parameterRoute = [
    {
        path: 'list',
        element: <ParameterListComponent />
    },
    {
        path: 'add',
        element: <ParameterAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/parameters/add" />
    },
    {
        path: 'edit/:id',
        element: <ParameterAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/parameters/list" />
    },
];