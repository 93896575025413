import {
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { 
  ErrorPage,
  GenericPage,
  Login, 
  Outdoor,
  Dashboard
} from "../../pages/";
import { Layout } from "../../root";
import { ProformApproved } from "../../components/outdoor/proform-approved";
import { ProformCancel } from "../../components/outdoor/proform-cancel";
import { ProformDecline } from "../../components/outdoor/proform-decline";
import { 
  salesRoute, 
  quoterRoute,
  optionsRoute,
  modelsRoute,
  storeRoute,
  userRoute,
  wareHouseRoutes,
  lunchRoutes,
} from "../routes";
import React from "react";

const WarehouseValidateProcess = React.lazy(() => import("../../pages/lib/warehouse-received"));

export const router = createBrowserRouter([
  {
    path:"/login",
    element: <Login />,
  },
  {
    path:"/outdoor",
    element: <Outdoor />,
    errorElement:<ErrorPage/>,
    children:[
      {
        path: "proform-approved/:id",
        element: <ProformApproved />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "proform-cancel/:id",
        element: <ProformCancel />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "proform-decline/:id",
        element: <ProformDecline />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "warehouse/recieved/*",
        element: <WarehouseValidateProcess />,
        errorElement:<ErrorPage/>,
      },
    ]
  },
  {
    path: "/",
    element: <Layout />,
    errorElement:<ErrorPage/>,
    children:[
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "fichas-tecnicas",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        // children:proformsRoute 
      },
      {
        path: "lunchs",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:lunchRoutes 
      },
      {
        path: "migraciones",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
      },
      {
        path: "models",
        errorElement:<ErrorPage/>,
        children:modelsRoute, 
      },
      {
        path: "options",
        errorElement:<ErrorPage/>,
        children:optionsRoute, 
      },
      {
        path: "quoter",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:quoterRoute 
      },
      {
        path: "sales",
        errorElement:<ErrorPage/>,
        children:salesRoute 
      },
      {
        path: "store",
        errorElement:<ErrorPage/>,
        children:storeRoute, 
      },
      {
        path: "user",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:userRoute, 
      },
      {
        path: "warehouse",
        element: <GenericPage />,
        errorElement:<ErrorPage/>,
        children:wareHouseRoutes 
      },
      {
        path: '',
        element: <Navigate replace to="dashboard" />
      },
    ],
    
  },
  {
    path:"",
    element: <Login />,
  },
  
  

]);