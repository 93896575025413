import React from "react";
import {
  Navigate,
} from "react-router-dom";


const ProformsAdd = React.lazy(() => import("../../../../../components/proformsv2/components/add/page"));
const ProformsList = React.lazy(() => import("../../../../../components/proformsv2/components/list"));

export const salesProforms2Routes = [
    {
      path: 'list',
      element: <ProformsList />,
    },
    {
      path: 'add',
      element: <ProformsAdd />,
    },
    {
      path: 'edit',
      element: <Navigate to="/sales/proforms/add" />
    },
    {
      path: 'edit/:id',
      element: <ProformsAdd />
    },
    {
      path: '',
      element: <Navigate replace to="/sales/proforms/add" />
    },
  ]