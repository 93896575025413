import React from "react";
import {
  Navigate,
} from "react-router-dom";


const OpsAdd = React.lazy(() => import("../../../../../components/ops/components/add/page"));
const OpsList = React.lazy(() => import("../../../../../components/ops/components/list/page"));
const OpsPending = React.lazy(() => import("../../../../../components/ops/components/pending/page"));

export const salesOpsRoutes = [
    {
      path: 'list',
      element: <OpsList />,
    },
    {
      path: 'add',
      element: <OpsAdd />,
    },
    {
      path: 'pending',
      element: <OpsPending />,
    },
    {
      path: 'edit',
      element: <Navigate to="/sales/ops/add" />
    },
    {
      path: 'edit/:id',
      element: <OpsAdd />
    },
    {
      path: '',
      element: <Navigate replace to="/sales/ops/list" />
    },
  ]