import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useWidth } from "../../../../../libs/hooks";
import {
  Badge,
  message,
  Select,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import { CardComponent } from "./card-component";
import { TableComponent } from "./table-component";
import {
  countLunchTicketsByStatus,
  countLunchTicketsTotals,

} from "../../../../../libs/apis";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

export const LunchComponent = ({ options }: { options: any[] }) => {
  const width = useWidth();
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const [valueTotals, setValueTotals] = useState("Hoy");
  const [valueParaLlevar, setValueParaLlevar] = useState("Hoy");
  const [valueServido, setValueServido] = useState("Hoy");
  const [valueInProgress, setValueInProgress] = useState("Hoy");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalValues, setTotalValues] = useState(0);
  const [totalParaLlevar, setTotalParaLlevar] = useState(0);
  const [totalServido, setTotalServido] = useState(0);
  const [totalInProgress, setTotalInProgress] = useState(0);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      handleGetTotals("hoy");
      handleGetParaLlevar("hoy");
      handleGetServido("hoy");
      handleGetInProgress("hoy");
    } catch (err: any) {
      message.error(err.toString());
    }
  };
  const handleUpdateTotals = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueTotals(fill.label);
      handleGetTotals(fill.value);
    },
    [valueTotals]
  );

  const handleUpdateParaLlevar = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueParaLlevar(fill.label);
      handleGetParaLlevar(fill.value);
    },
    [valueParaLlevar]
  );

  const handleUpdateServido = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueServido(fill.label);
      handleGetServido(fill.value);
    },
    [valueServido]
  );

  const handleUpdateInProgress = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueInProgress(fill.label);
      handleGetInProgress(fill.value);
    },
    [valueInProgress]
  );

  const handleGetTotals = async (value: string) => {
    try {
      const { data: dataTotals } = await countLunchTicketsTotals(value);
      if (!dataTotals) return;
      setTotalQuantity(dataTotals.total);
      setTotalValues(dataTotals.sumValor);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetParaLlevar = async (value: string) => {
    try {
      const { data: dataLlevar } = await countLunchTicketsByStatus(value, 'llevar');
      if (!dataLlevar) return;
      setTotalParaLlevar(dataLlevar.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetServido = async (value: string) => {
    try {
      const { data: dataServido } = await countLunchTicketsByStatus(value, 'servido');
      if (!dataServido) return;
      setTotalServido(dataServido.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetInProgress = async (value: string) => {
    try {
      const { data: dataProgreso } = await countLunchTicketsByStatus(value, 'progreso');
      if (!dataProgreso) return;
      setTotalInProgress(dataProgreso.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };


  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Almuerzos Solicitados"
            quantity={totalQuantity}
            handleUpdate={handleUpdateTotals}
            options={options}
            color="tomato"
            value={valueTotals}
            total={totalValues}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Almuerzos En Progeso"
            quantity={totalInProgress}
            handleUpdate={handleUpdateInProgress}
            options={options}
            color="orange"
            value={valueInProgress}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Almuerzos Servidos"
            quantity={totalServido}
            handleUpdate={handleUpdateServido}
            options={options}
            color="green"
            value={valueServido}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Almuerzos Para Llevar"
            quantity={totalParaLlevar}
            handleUpdate={handleUpdateParaLlevar}
            options={options}
            color="blue"
            value={valueParaLlevar}
          />
        </Grid>
        <Grid item xs={12}>
          <TableComponent />
        </Grid>
      </Grid>
    </Box>
  );
};
