export const generatePad = (id: number) => {
    return id.toString().padStart(8, "0");
};


export function separator(numb: string) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
};

export const generateRandomPassword = () => {
    const chars = '0123456789abcdefghjkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ';
    const passwordLength = 7;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
};

export function formatDate(dateString: any) {
    if (!dateString) {
      return "No Asignado";
    }
  
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
  
    const date = new Date(dateString);
  
    // Verificar si la fecha es inválida
    if (isNaN(date.getTime())) {
      return "No Asignado";
    }
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} / ${month} / ${year}`;
  }