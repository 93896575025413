import React, { useEffect } from "react";
import { URL_BACKEND } from "../../libs/constants";
import { useParams } from "react-router-dom";
import { message } from "antd";

const WarehouseValidateProcess = () => {
  const params = useParams();

  useEffect(() => {
    const encryptedId = params["*"];
    if (encryptedId) {
      fetch(
        `${URL_BACKEND}/api/warehouse/change-process-to-recieved/${encryptedId}`
      )
        .then((response) => {
          if (response.ok) {
            message.success("Proceso validado correctamente");
            setTimeout(() => {
              window.close(); // Cerrar la pestaña automáticamente
            }, 3000);
          } else {
            message.error("Error al validar el proceso");
          }
        })
        .catch((error) => {
          message.error(`Error al validar el proceso: ${error}`);
        });
    } else {
      message.error("No se encontró el ID en la URL");
    }
  }, []);

  return (
    <div>
      <h1>Validando el proceso...</h1>
      <p>Espera unos segundos mientras validamos tu solicitud.</p>
    </div>
  );
};

export default WarehouseValidateProcess;
