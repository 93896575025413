import { useQuery } from "@tanstack/react-query";
import { lunchTicketApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsLunchTicketI,
  LunchTicketI,
  LunchTicketParamsAdvancedI,
  LunchTicketResponseAdvancedI,
  LunchTicketUpdateCancelI,
  LunchTicketCountTotalsI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getLunchTickets = async ({
  pagination,
  sorting,
  globalFilter,
  lunch,
  person,
  statusTicket,
  range,
  rangeVal,
}: PropsLunchTicketI): Promise<ServiceResponseI<DataI<LunchTicketI>>> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    lunch && params.append("lunch", JSON.stringify(lunch));
    person && params.append("person", JSON.stringify(person));
    statusTicket && params.append("statusTicket", JSON.stringify(statusTicket));
    rangeVal && params.append("range", JSON.stringify(range));

    const { data } = await lunchTicketApi.get<
      ServiceResponseI<DataI<LunchTicketI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }

    throw new Error((obj?.data as Error).message);
  }
};

export const getAllLunchTickets = async (): Promise<
  ServiceResponseI<LunchTicketI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await lunchTicketApi.get<ServiceResponseI<LunchTicketI[]>>(
      `/all-no-pagination`,
      { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getReportAdvancedLunchTickets = async ({
  consultType,
  consultDate,
  consultValue,
  user,
  area,
  range,
  date,
}: LunchTicketParamsAdvancedI): Promise<
  ServiceResponseI<LunchTicketResponseAdvancedI>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    consultType && params.append("consultType", JSON.stringify(consultType));
    consultDate && params.append("consultDate", JSON.stringify(consultDate));
    consultValue && params.append("consultValue", JSON.stringify(consultValue));
    user && params.append("user", JSON.stringify(user));
    area && params.append("area", JSON.stringify(area));
    range && params.append("range", JSON.stringify(range));
    date && params.append("date", JSON.stringify(date));

    const { data } = await lunchTicketApi.get<
      ServiceResponseI<LunchTicketResponseAdvancedI>
    >(`/generate-table-advanced`, {
      params,
      headers: { Authorization: validateToken },
    });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getReportAdvancedTotalizeLunchTickets = async ({
  consultType,
  consultDate,
  consultValue,
  user,
  area,
  range,
  date,
  supplierId,
}: LunchTicketParamsAdvancedI): Promise<
  ServiceResponseI<LunchTicketResponseAdvancedI>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    consultType && params.append("consultType", JSON.stringify(consultType));
    consultDate && params.append("consultDate", JSON.stringify(consultDate));
    consultValue && params.append("consultValue", JSON.stringify(consultValue));
    user && params.append("user", JSON.stringify(user));
    area && params.append("area", JSON.stringify(area));
    range && params.append("range", JSON.stringify(range));
    date && params.append("date", JSON.stringify(date));
    supplierId && params.append("supplierId", JSON.stringify(supplierId));

    const { data } = await lunchTicketApi.get<
      ServiceResponseI<LunchTicketResponseAdvancedI>
    >(`/generate-table-advanced-totalize`, {
      params,
      headers: { Authorization: validateToken },
    });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getReportAdvancedTotalizeDateLunchTickets = async ({
  consultType,
  consultDate,
  consultValue,
  user,
  area,
  range,
  date,
}: LunchTicketParamsAdvancedI): Promise<
  ServiceResponseI<LunchTicketResponseAdvancedI>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    consultType && params.append("consultType", JSON.stringify(consultType));
    consultDate && params.append("consultDate", JSON.stringify(consultDate));
    consultValue && params.append("consultValue", JSON.stringify(consultValue));
    user && params.append("user", JSON.stringify(user));
    area && params.append("area", JSON.stringify(area));
    range && params.append("range", JSON.stringify(range));
    date && params.append("date", JSON.stringify(date));

    const { data } = await lunchTicketApi.get<
      ServiceResponseI<LunchTicketResponseAdvancedI>
    >(`/generate-table-advanced-totalize-date`, {
      params,
      headers: { Authorization: validateToken },
    });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const countLunchTicketsTotals = async (type:string, person?:number): Promise<ServiceResponseI<LunchTicketCountTotalsI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    type && params.append("type", type.toString());
    person && params.append("person", person.toString());

    const { data } = await lunchTicketApi.get<
      ServiceResponseI<LunchTicketCountTotalsI>
    >(`/count-totals`, { params, headers: { Authorization: validateToken } });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const countLunchTicketsByStatus = async (
    type:string,
    status:string,
    person?:number,
  ): Promise<ServiceResponseI<{total:number}>> => {
    try {
      const validateToken = ValidateCurrentToken();
      if (!validateToken) throw new Error("TOKEN INVALIDO");
  
      const params = new URLSearchParams();
  
      type && params.append("type", type.toString());
      person && params.append("person", person.toString());
      status && params.append("status", status.toString());
  
      const { data } = await lunchTicketApi.get<
        ServiceResponseI<{total:number}>
      >(`/count-by-status`, { params, headers: { Authorization: validateToken } });
  
      return data;
    } catch (err) {
      const obj = (err as AxiosError).response;
      if (obj?.status === 401) {
        console.error(obj.statusText);
        LogoutSystem();
      }
      throw new Error((obj?.data as Error).message);
    }
  };

export const lunchTicketPost = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.post<ServiceResponseI<string>>(
      "",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/${id}`,
      updateForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketForGoPut = async <T>(
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/for-go/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketPenalizePut = async <T>(
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/penalize/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketById = async (
  id: number
): Promise<ServiceResponseI<LunchTicketI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.get<ServiceResponseI<LunchTicketI>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketServedById = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.get<ServiceResponseI<string>>(
      `/ticket-served/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketOnProgressById = async (
  id: number
): Promise<ServiceResponseI<LunchTicketI[]>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.get<ServiceResponseI<LunchTicketI[]>>(
      `/tickets-on-progress/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.delete<ServiceResponseI<string>>(
      `/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketPrint = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.get<ServiceResponseI<string>>(
      `/print-ticket-by-id/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const lunchTicketCancel = async (
  id: number,
  payload: LunchTicketUpdateCancelI
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/cancel/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const subsidyMassiveTickets = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/update-free-all-by-lunch-id/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const subsidyOneTicket = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await lunchTicketApi.put<ServiceResponseI<string>>(
      `/update-free-one-by-id/${id}`,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useLunchTickets = ({
  sorting,
  pagination,
  globalFilter,
  person,
  lunch,
  statusTicket,
  range,
  rangeVal,
}: PropsLunchTicketI) => {
  const lunchTicketsQuery = useQuery(
    [
      "lunchTickets",
      {
        pagination,
        sorting,
        globalFilter,
        lunch,
        person,
        statusTicket,
        range,
        rangeVal,
      },
    ],
    () =>
      getLunchTickets({
        pagination,
        sorting,
        globalFilter,
        lunch,
        person,
        statusTicket,
        range,
        rangeVal,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    lunchTicketsQuery,
  };
};
