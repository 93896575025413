import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Backdrop,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getAllProformStatus, parameterGetByCode, proformsApi, useProforms, useProjects } from "../../../../../libs/apis";
import {
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  DoDisturbOn as DoDisturbOnIcon,
} from "@mui/icons-material";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import { ListProformsI, ParameterI } from "../../../../../libs/interfaces";
// import "../../../libs/css/list.css";
import {
  usersGetbyToken
} from "../../../../../libs/apis";
import { enumsGenerals } from "../../../../../libs/enums";
import React from "react";
import { separator } from "../../../../../libs/helpers";
import { ChipStatus } from "../../../../../components/proforms/components/add/components/lib/chip-status";
import {
  HEIGHT_TABLE_CONTAINER,
  STATUS_PROFORM_SEND_GERENCIA_COMERCIAL_CODE,
  TYPE_USER_ADMINISTRADOR_CODE,
  TYPE_USER_GERENTE_CODE,
} from "../../../../../libs/constants";
import {
  Button,
  Popconfirm,
  Tooltip as TooltipAntd,
} from 'antd';
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { useWidth } from "../../../../../libs/hooks";

const routeEdit = "/sales/proforms/edit/";

export const TableComponent = () => {
  const navigate = useNavigate();
  const width = useWidth();
  const { enqueueSnackbar } = useSnackbar();
  const [sorting, setSorting] = useState([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState({
    executive: false
  })
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: width === "ab" ? 10 : width === "xl" ? 10 : 10,
  });
  const [statusId, setStatusId] = useState<null | number>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const { projectsQuery } = useProjects({
    sorting,
    pagination,
    globalFilter,
    // status: statusId,
  });
  const { data, isLoading, refetch } = projectsQuery;
  const [rowSelection, setRowSelection] = useState({});

  const [isDeleting, setDeleting] = useState(false);
  const [isManager, setManager] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [statuses, setStatuses] = useState<ParameterI[]>([]);
  const [elStatus, setElStatus] = useState("");

  useEffect(() => {
    handleData();
  }, [])


  const handleData = async () => {
    try {
      const dataUser = await usersGetbyToken();
      console.log(dataUser)
      switch (dataUser?.data?.typeName) {
        case enumsGenerals.user_manager_proform:
          setManager(true);
          setColumnVisibility({ ...columnVisibility, executive: true });
          setOpenBackdrop(false);
          break;
        case enumsGenerals.user_administrator:
          setManager(true);
          setColumnVisibility({ ...columnVisibility, executive: true });
          setOpenBackdrop(false);
          break;
        default:
          console.log('No Aplica');
          setOpenBackdrop(false);
      }
      const { data: dataParameter } = await getAllProformStatus();
      setStatuses(dataParameter!);

      switch (dataUser?.data?.typeCode) {
        case TYPE_USER_ADMINISTRADOR_CODE:
          setManager(true);
          setOpenBackdrop(false);
          break;
        case TYPE_USER_GERENTE_CODE:
          const { data: dataStatus } = await parameterGetByCode(STATUS_PROFORM_SEND_GERENCIA_COMERCIAL_CODE);
          setStatusId(dataStatus!.id);
          setElStatus(dataStatus!.value);
          setManager(true);
          setOpenBackdrop(false);
          break;
        default:
          console.log('No Aplica');
          setOpenBackdrop(false);
      }
    } catch (err: any) {
      console.log(err);
      setOpenBackdrop(false);
      enqueueSnackbar(err.toString(), { variant: 'error' })
    }
  };

  const handleNavigate = useCallback((row: ListProformsI) => {
    const routePath = routeEdit + row.id;
    navigate(routePath)
  }, []);

  const columns = useMemo<MRT_ColumnDef<ProjectI>[]>(
    () => [
      {
        accessorKey: "id",
        id: "id",
        header: "id",
        minSize: 90,
        maxSize: 90,
        size: 90,
        enableEditing: false,
        muiTableBodyCellProps: ({ row, cell }) => ({
          onClick: () => {
            handleNavigate(row.original)
          },
        }),
        Cell: ({ cell }) => {
          return <a className='a-proform'>{cell.getValue() as string}</a>;
        },
      },
      {
        accessorKey: "project",
        id: "project",
        header: "Proyecto",
        minSize: 100,
        size: 250,
        muiTableBodyCellProps: ({ cell, row }) => ({
          onClick: () => {
            handleNavigate(row.original)
          },
        }),
        Cell: ({ cell }) => {
          return <a className='b-proform'>{cell.getValue() as string}</a>;
        },
      },
      {
        accessorKey: "statusProject",
        id: "statusProject",
        header: "Status",
        minSize: 160,
        enableEditing: false,
        size: 160,
        Cell: ({ cell }) => {
          return<p>STATUS</p>;
        },
      },

      {
        accessorKey: "executive",
        id: "executive",
        header: "Ejecutiva",
        minSize: 150,
        enableEditing: false,
        size: 150,
      },
      {
        accessorKey: "client",
        id: "client",
        header: "Cliente",
        minSize: 100,
        enableEditing: false,
        size: 200,
      },
      {
        accessorKey: "subtotalNeto",
        id: "subtotalNeto",
        header: "Subtotal Neto",
        minSize: 120,
        enableEditing: false,
        size: 120,
        Cell: ({ cell }: { cell: any }) => {
          return separator(cell.getValue() ?? 0);
        },
      },

      {
        accessorKey: "userClientName",
        id: "userClientName",
        header: "Usuario",
        minSize: 100,
        enableEditing: false,
        size: 130,
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: "Creacion",
        minSize: 120,
        enableEditing: false,
        size: 120,
      },

    ],
    []
  );

  const handleDeleteRow = useCallback(async (row: ListProformsI) => {
    setDeleting(true);
    try {
      const infoDelete = await proformsApi.delete(`/${row.id}`);
      enqueueSnackbar(infoDelete.data, { variant: "success" });
      projectsQuery.refetch();
      setDeleting(false);
    } catch (err) {
      enqueueSnackbar((err as Error).message, { variant: "error" });
      setDeleting(false);
    }
  }, []);

  const handleSelectStatus = (e: any, value: string | null) => {
    if (!value) {
      setElStatus("");
      setStatusId(null);
      return;
    }

    const fill = statuses.find(el => el.value === value);
    if (!fill) return;

    setElStatus(value);
    setStatusId(fill.id);
  };


  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!openBackdrop ? <MaterialReactTable
        //OPTIONS
        enableRowActions={false}
        enableStickyHeader
        enableStickyFooter
        enableDensityToggle
        enableColumnOrdering
        enableColumnResizing
        enableEditing={false}
        enableGlobalFilter

        manualFiltering
        manualPagination
        manualSorting

        muiTableContainerProps={({ table }) => {
          return {
            sx: {
              minHeight: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
              maxHeight: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
              height: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
            },
          };
        }}
        columns={columns}

        data={data?.data?.dato ?? []}

        initialState={{
          density: "compact",
          rowSelection,
          sorting,
          columnVisibility,
        }}
        state={{
          isLoading,
          columnVisibility,
          globalFilter,
          rowSelection,
          pagination,
          sorting
        }}
        rowCount={data?.data?.total ?? 0}
        onRowSelectionChange={setRowSelection}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        // onColumnVisibilityChange={setColumnVisibility}

        renderTopToolbarCustomActions={() => (
          <Grid
            alignItems="center"
            container
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <Autocomplete
                id="gerencia_proform_list_filter_status"
                options={statuses.map((el) => el.value)}
                value={elStatus || ""}
                size="small"
                sx={{ width: 200 }}
                disabled={statuses.length === 0 ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    color="primary"
                  />
                )}
                onChange={handleSelectStatus}
              />
            </Grid> */}

          </Grid>
        )}


        localization={MRT_Localization_ES}
        // OPTIONS SELECTION ROW
        selectAllMode="all"
        positionToolbarAlertBanner="none"
      /> : null}
    </Fragment>
  );
};




