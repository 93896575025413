import { useQuery } from "@tanstack/react-query";
import { monitoringServiceApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsMonitoringServiceI,
  MonitoringServiceI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getMonitoringServices = async ({
  pagination,
  sorting,
  globalFilter,
}: PropsMonitoringServiceI): Promise<
  ServiceResponseI<DataI<MonitoringServiceI>>
> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));

    //headers: { 'Authorization': validateToken
    const { data } = await monitoringServiceApi.get<
      ServiceResponseI<DataI<MonitoringServiceI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllMonitoringServices = async (): Promise<
  ServiceResponseI<MonitoringServiceI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await monitoringServiceApi.get<
      ServiceResponseI<MonitoringServiceI[]>
    >(`/all-no-pagination`, { headers: { Authorization: validateToken } });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const monitoringServicePost = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await monitoringServiceApi.post<ServiceResponseI<string>>(
      "",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const monitoringServicePut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await monitoringServiceApi.put<ServiceResponseI<string>>(
      `/${id}`,
      updateForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const monitoringServiceById = async (
  id: number
): Promise<ServiceResponseI<MonitoringServiceI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await monitoringServiceApi.get<
      ServiceResponseI<MonitoringServiceI>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const monitoringServiceDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await monitoringServiceApi.delete<
      ServiceResponseI<string>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useMonitoringServices = ({
  sorting,
  pagination,
  globalFilter,
}: PropsMonitoringServiceI) => {
  const monitoringServicesQuery = useQuery(
    ["monitoringServices", { pagination, sorting, globalFilter }],
    () => getMonitoringServices({ pagination, sorting, globalFilter }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    monitoringServicesQuery,
  };
};
