import React from "react";
import { ConfigProvider, Image, Button, Space } from "antd";
import {
  CloseCircleOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../../../libs/constants";
import "../../css/visor-image.css";

export const OneImageVisor = ({ image }: { image: string }) => {
  return (
    <div className="container-image-one">
      <ConfigProvider
        theme={{
          token: {
            colorBgMask: "rgba(7, 40, 105, 0.85);",
          },
        }}
      >
        <Image
          alt="CreativeGroup"
          height="140px"
          width="250px"
          src={image}
          style={{
            objectFit: "contain",
            background: "none",
            borderRadius: "5px",
          }}
          preview={{
            closeIcon: (
              <ConfigProvider
                theme={{
                  token: {
                    colorBgBase: "#CAD226",
                  },
                }}
              >
                <Button
                  size="large"
                  shape="circle"
                  icon={<CloseCircleOutlined style={{ color: "red" }} />}
                />
              </ConfigProvider>
            ),
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onActive,
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                  onReset,
                },
              }
            ) => (
              <ConfigProvider
                theme={{
                  token: {
                    colorBgBase: "#CAD226",
                  },
                }}
              >
                <Space size={12} className="toolbar-wrapper">
                  <Button
                    onClick={() => onActive?.(-1)}
                    shape="circle"
                    icon={<LeftOutlined style={{ color: COLOR_PRIMARY }} />}
                  />
                  <Button
                    onClick={() => onActive?.(1)}
                    shape="circle"
                    icon={<RightOutlined style={{ color: COLOR_PRIMARY }} />}
                  />
                  <Button
                    onClick={onFlipY}
                    shape="circle"
                    icon={
                      <SwapOutlined
                        rotate={90}
                        style={{ color: COLOR_PRIMARY }}
                      />
                    }
                  />
                  <Button
                    onClick={onFlipX}
                    shape="circle"
                    icon={<SwapOutlined style={{ color: COLOR_PRIMARY }} />}
                  />
                  <Button
                    onClick={onRotateLeft}
                    shape="circle"
                    icon={
                      <RotateLeftOutlined style={{ color: COLOR_PRIMARY }} />
                    }
                  />
                  <Button
                    onClick={onRotateRight}
                    shape="circle"
                    icon={
                      <RotateRightOutlined style={{ color: COLOR_PRIMARY }} />
                    }
                  />
                  <Button
                    onClick={onZoomOut}
                    disabled={scale === 1}
                    shape="circle"
                    icon={
                      <ZoomOutOutlined
                        style={{
                          color: scale === 1 ? "white" : COLOR_PRIMARY,
                        }}
                      />
                    }
                  />
                  <Button
                    onClick={onZoomIn}
                    disabled={scale === 50}
                    shape="circle"
                    icon={<ZoomInOutlined style={{ color: COLOR_PRIMARY }} />}
                  />
                  <Button
                    onClick={onReset}
                    shape="circle"
                    icon={<UndoOutlined style={{ color: COLOR_PRIMARY }} />}
                  />
                </Space>
              </ConfigProvider>
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export const MultipleImageVisor = ({
  images,
  imageFront,
}: {
  images: any[];
  imageFront: string;
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgMask: "rgba(7, 40, 105, 0.85);",
        },
      }}
    >
      <Image.PreviewGroup
        items={images.map((el) => el.src)}
        preview={{
          closeIcon: (
            <ConfigProvider
              theme={{
                token: {
                  colorBgBase: "#CAD226",
                },
              }}
            >
              <Button
                size="large"
                shape="circle"
                icon={<CloseCircleOutlined style={{ color: "red" }} />}
              />
            </ConfigProvider>
          ),
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onActive,
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
                onReset,
              },
            }
          ) => (
            <ConfigProvider
              theme={{
                token: {
                  colorBgBase: "#CAD226",
                },
              }}
            >
              <Space size={12} className="toolbar-wrapper">
                <Button
                  onClick={() => onActive?.(-1)}
                  shape="circle"
                  icon={<LeftOutlined style={{ color: COLOR_PRIMARY }} />}
                />
                <Button
                  onClick={() => onActive?.(1)}
                  shape="circle"
                  icon={<RightOutlined style={{ color: COLOR_PRIMARY }} />}
                />
                <Button
                  onClick={onFlipY}
                  shape="circle"
                  icon={
                    <SwapOutlined
                      rotate={90}
                      style={{ color: COLOR_PRIMARY }}
                    />
                  }
                />
                <Button
                  onClick={onFlipX}
                  shape="circle"
                  icon={<SwapOutlined style={{ color: COLOR_PRIMARY }} />}
                />
                <Button
                  onClick={onRotateLeft}
                  shape="circle"
                  icon={<RotateLeftOutlined style={{ color: COLOR_PRIMARY }} />}
                />
                <Button
                  onClick={onRotateRight}
                  shape="circle"
                  icon={
                    <RotateRightOutlined style={{ color: COLOR_PRIMARY }} />
                  }
                />
                <Button
                  onClick={onZoomOut}
                  disabled={scale === 1}
                  shape="circle"
                  icon={
                    <ZoomOutOutlined
                      style={{
                        color: scale === 1 ? "white" : COLOR_PRIMARY,
                      }}
                    />
                  }
                />
                <Button
                  onClick={onZoomIn}
                  disabled={scale === 50}
                  shape="circle"
                  icon={<ZoomInOutlined style={{ color: COLOR_PRIMARY }} />}
                />
                <Button
                  onClick={onReset}
                  shape="circle"
                  icon={<UndoOutlined style={{ color: COLOR_PRIMARY }} />}
                />
              </Space>
            </ConfigProvider>
          ),
        }}
      >
        <div className="container-image-one">
          <Image
            alt="CreativeGroup"
            height="140px"
            width="250px"
            // src={data.src}
            src={imageFront}
            style={{
              objectFit: "contain",
              background: "none",
              borderRadius: "5px",
            }}
            preview={{
              closeIcon: (
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgBase: "#CAD226",
                    },
                  }}
                >
                  <Button
                    size="large"
                    shape="circle"
                    icon={<CloseCircleOutlined style={{ color: "red" }} />}
                  />
                </ConfigProvider>
              ),
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                }
              ) => (
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgBase: "#CAD226",
                    },
                  }}
                >
                  <Space size={12} className="toolbar-wrapper">
                    <Button
                      onClick={() => onActive?.(-1)}
                      shape="circle"
                      icon={<LeftOutlined style={{ color: COLOR_PRIMARY }} />}
                    />
                    <Button
                      onClick={() => onActive?.(1)}
                      shape="circle"
                      icon={<RightOutlined style={{ color: COLOR_PRIMARY }} />}
                    />
                    <Button
                      onClick={onFlipY}
                      shape="circle"
                      icon={
                        <SwapOutlined
                          rotate={90}
                          style={{ color: COLOR_PRIMARY }}
                        />
                      }
                    />
                    <Button
                      onClick={onFlipX}
                      shape="circle"
                      icon={<SwapOutlined style={{ color: COLOR_PRIMARY }} />}
                    />
                    <Button
                      onClick={onRotateLeft}
                      shape="circle"
                      icon={
                        <RotateLeftOutlined style={{ color: COLOR_PRIMARY }} />
                      }
                    />
                    <Button
                      onClick={onRotateRight}
                      shape="circle"
                      icon={
                        <RotateRightOutlined style={{ color: COLOR_PRIMARY }} />
                      }
                    />
                    <Button
                      onClick={onZoomOut}
                      disabled={scale === 1}
                      shape="circle"
                      icon={
                        <ZoomOutOutlined
                          style={{
                            color: scale === 1 ? "white" : COLOR_PRIMARY,
                          }}
                        />
                      }
                    />
                    <Button
                      onClick={onZoomIn}
                      disabled={scale === 50}
                      shape="circle"
                      icon={<ZoomInOutlined style={{ color: COLOR_PRIMARY }} />}
                    />
                    <Button
                      onClick={onReset}
                      shape="circle"
                      icon={<UndoOutlined style={{ color: COLOR_PRIMARY }} />}
                    />
                  </Space>
                </ConfigProvider>
              ),
            }}
          />
        </div>
      </Image.PreviewGroup>
    </ConfigProvider>
  );
};
