import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Badge, Select } from "antd";
import "../../css/card-css.css";

interface CardComponentPropsI {
  width: string;
  title: string;
  quantity: number;
  handleUpdate: (value: string) => void;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  color: string;
  value: string;
  total?: number;
}

export const CardComponent = ({
  width,
  quantity,
  title,
  handleUpdate,
  options,
  color,
  value,
  total,
}: CardComponentPropsI) => {
  return (
    <div
      className="card-component"
      // elevation={3}
    >
      <Badge
        count={quantity}
        color={color}
        showZero
        style={{
          width: 50,
          height: 50,
          fontSize: 25,
          lineHeight: "50px",
          borderRadius: "50%",
        }}
      />
      {total ? (
        <span
          // variant="h5"
          // component="span"
          style={{
            // ml: 1,
            fontWeight: "bold",
            fontSize: 14,
            position: "absolute",
            left: 5,
            bottom: 5,
            // color:"darkgray",
            // textAlign: "center",
          }}
        >
          ${total}
        </span>
      ) : null}
      <span className="title">{title}</span>
      <Select
        variant="filled"
        style={{
          width: width === "xs" ? "auto" : 100,
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
        size="small"
        value={value}
        onChange={handleUpdate}
        options={options}
      />
    </div>
  );
};
