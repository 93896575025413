import React from "react";
import { GenericPage } from "../../../../../pages";

import { bankRoute } from "./models-bank.route";
import { brandRoute } from "./models-brand.route";
import { catalogueRoute } from "./models-catalogue.route";
import { parameterRoute } from "./models-parameter.route";
import { clientRoute } from "./models-client.route";
import { groupRoute } from "./models-group.route";
import { lineRoute } from "./models-line.route";
import { moduleRoute } from "./models-module.route";
import { optionRoute } from "./models-options.route";
import { supplierTypeRoute } from "./models-supplier-type.route";
import { taskRoute } from "./models-task.route";
import { presentationRoute } from "./models-presentation.route";
import { cantonRoute } from "./models-canton";
import { countryRoute } from "./models-country";
import { provinceRoute } from "./models-province.route";
import { parishRoute } from "./models-parish.route";


export const modelsRoute = [
  {
    path: 'banks',
    element: <GenericPage />,
    children: bankRoute,
  },
  {
    path: 'brands',
    element: <GenericPage />,
    children: brandRoute,
  },
  {
    path: 'catalogues',
    element: <GenericPage />,
    children: catalogueRoute,
  },
  {
    path: 'cantons',
    element: <GenericPage />,
    children: cantonRoute,
  },
  {
    path: 'clients',
    element: <GenericPage />,
    children: clientRoute,
  },
  {
    path: 'countries',
    element: <GenericPage />,
    children: countryRoute,
  },
  {
    path: 'groups',
    element: <GenericPage />,
    children: groupRoute,
  },
  {
    path: 'lines',
    element: <GenericPage />,
    children: lineRoute,
  },
  {
    path: 'modules',
    element: <GenericPage />,
    children: moduleRoute,
  },
  {
    path: 'options',
    element: <GenericPage />,
    children: optionRoute,
  },
  {
    path: 'parameters',
    element: <GenericPage />,
    children: parameterRoute,
  },
  {
    path: 'parishes',
    element: <GenericPage />,
    children: parishRoute,
  },
  {
    path: 'presentations',
    element: <GenericPage />,
    children: presentationRoute,
  },
  {
    path: 'provinces',
    element: <GenericPage />,
    children: provinceRoute,
  },
  {
    path: 'suppliers-types',
    element: <GenericPage />,
    children: supplierTypeRoute,
  },
  {
    path: 'tasks',
    element: <GenericPage />,
    children: taskRoute,
  },
]